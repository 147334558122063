import React, { useCallback } from "react";
import { HelloBar } from "../../ui/HelloBar";
import { currentLanguage } from "@src/utils/routes";
import { navigateTo } from "@src/utils/location";
import { useLocation } from "@reach/router";

const LOCALES = ["en-us"];

interface DistrictsHelloBarProps {
  zIndex?: number;
}

const DistrictsHelloBar = ({ zIndex }: DistrictsHelloBarProps) => {
  const location = useLocation();
  const lang = currentLanguage();

  if (location.pathname.includes("/districts")) return null;
  if (!LOCALES.includes(lang)) return null;

  return (
    <HelloBar
      cookieName="districts"
      to="/districts?utm_source=hpnav"
      text="ClassDojo for Districts: More engaged families. More successful districts."
      trackingEventName="web.external.districts_hello_bar_click"
      css={{ zIndex }}
    />
  );
};

export default DistrictsHelloBar;
