export const ExternalSwitches = {
  WEB_ATTRIBUTION_SURVEY: "web_attribution_survey_v2",
  WEB_EXTERNAL_HOMEPAGE_EXPERIMENT_2024: "web_external_homepage_experiment_2024_v7",
  WEB_EXTERNAL_LOGIN_OIDC_REDIRECT: "web_external_login_oidc_redirect_v1",
} as const;

export const LiteSwitches = {
  WEB_LITE_REDIRECT_EMAIL_VERIFICATION: "web_lite_redirect_email_verification_v3",
} as const;
export const TeacherSwitches = {} as const;

export const featureSwitches = Object.values({ ...ExternalSwitches, ...TeacherSwitches, ...LiteSwitches });

export const defaultFSValues = featureSwitches.reduce((acc, fs) => ({ ...acc, [fs]: "off" }), {});
