import styled from "@emotion/styled";

const StyledLayoutMain = styled.main`
  box-sizing: border-box;
  min-width: 0;
  margin: 0;
  // height: 100%;
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
`;

export default StyledLayoutMain;
