import React from "react";
import { css, Global } from "@emotion/react";
import { ThemeProvider } from "@emotion/react";
import Helmet from "react-helmet";
import BlogHeader from "@src/components/blog/BlogHeader";
import Footer from "@src/components/Footer";
import Modal from "react-modal";
import normalize from "../styles/normalize";
import theme from "../styles/theme";
import { ModalProvider, ModalRoot } from "@src/components/modals/ModalController";
import { TranslationProvider } from "@src/components/translation/TranslationContext";
import { AppDataContextProvider } from "@src/components/AppDataContext";
import OneTrustScript from "@src/components/partials/shared/OneTrustScript";
import "modern-normalize/modern-normalize.css";
import { generatingFor } from "@src/utils/routes";
import Spacer from "@src/components/Spacer";
import StyledLayoutMain from "@src/layouts/common/StyledLayoutMain";
import _ from "lodash";

Modal.setAppElement("#___gatsby");

type BlogLayoutProps = {
  children: any;
  pageContext: any;
};

const BlogLayout: React.FC<BlogLayoutProps> = ({ children, pageContext }) => {
  generatingFor.locale = _.get(pageContext, ["localeStrings", "currentLocale"], "en-US");
  return (
    <>
      <Helmet htmlAttributes={{ lang: pageContext.localeStrings.currentLocale }}>
        <link rel="stylesheet" href="https://static.classdojo.com/fonts/proxima/proximanova-default-stack.css" />
      </Helmet>
      <OneTrustScript />
      <Global
        styles={css`
          ${normalize};
          #___gatsby,
          #gatsby-focus-wrapper,
          main,
          html,
          body {
            height: 100%;
          }

          /* modal transitions:  */
          .ReactModal__Content {
            opacity: 0;
            transform: translate(0, -50%);
            transition: transform 300ms ease-out;
          }

          .ReactModal__Content--after-open {
            opacity: 1;
            transform: translate(0, 0);
          }

          .ReactModal__Content--before-close {
            opacity: 0;
            transform: translate(0, -50%);
          }
        `}
      />
      <TranslationProvider localeStrings={pageContext.localeStrings}>
        <ThemeProvider theme={theme}>
          <AppDataContextProvider>
            <ModalProvider>
              <>
                <ModalRoot />

                <StyledLayoutMain id="main-page-container">
                  <BlogHeader />
                  <Spacer marginTop="87px" height="1px" />
                  {children}
                  <Footer isBlog />
                </StyledLayoutMain>
              </>
            </ModalProvider>
          </AppDataContextProvider>
        </ThemeProvider>
      </TranslationProvider>
    </>
  );
};

export default BlogLayout;
